import {reactive} from 'vue'
import {acceptHMRUpdate, defineStore} from 'pinia'
import {useConnectionStore} from "~/stores/connection";


interface VersionData {
    loading: boolean,
    version?: string
}

export const useVersionsStore = defineStore('versions', () => {
    const versions: { [library: string]: VersionData } = reactive({})
    const {query} = useConnectionStore()

    const fetchVersion = async (lib: string) => {
        if (versions[lib]) {
            if(versions[lib].loading) {
                // Wait for loading to complete
                return await new Promise<any>((resolve) => {
                    setTimeout(() => {
                        fetchVersion(lib).then(resolve)
                    }, 100)
                })
            }
            if(!versions[lib].version) {
                return null
            }
            return versions[lib].version
        }

        versions[lib] = {loading: true}

        return await query('version', {lib}).then(async (r) => {
            versions[lib].loading = false
            versions[lib].version = r.version as string
            return r.version
        })
    }

    return {versions, fetchVersion}
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useVersionsStore, import.meta.hot))
}
